
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  nextTick,
  onMounted,
} from "vue";
import moment from "moment";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import useFetch from "@/hooks/useFetch";
import useTableHeight from "@/hooks/useTableHeight";
import {
  getSubscribedBu,
  searchReleaseBatchContent,
  viewSml,
} from "@/API/approve";
import { message } from "ant-design-vue";
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";

import {
  DetailParams,
  QueryDetailParams,
  DetailAttachmentProgram,
  DetailAttachment,
  Sml,
} from "./types";
import blobDownload from "@/utils/blobDownload";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
type Key = ColumnProps["key"];

export default defineComponent({
  name: "Sale Measure Detail",
  setup() {
    const { tableHeight } = useTableHeight();
    const route = useRoute();

    const { t } = useI18n();

    const allDealerVisiable = ref<boolean>(false);

    const columns = [
      {
        title: t("salesmeasure.programcode"),
        dataIndex: "programCode",
        key: "programCode",
      },
      {
        title: t("salesmeasure.programnamecn"),
        dataIndex: "programNameCn",
        key: "programNameCn",
      },
      {
        title: t("salesmeasure.programnameen"),
        dataIndex: "programNameEn",
        key: "programNameEn",
      },
      {
        title: t("salesmeasure.period"),
        dataIndex: "period",
        key: "period",
        slots: { customRender: "period" },
      },
    ];

    const columnsAttachment = [
      {
        title: t("salesmeasure.attachmentname"),
        dataIndex: "attachmentName",
        key: "attachmentName",
      },
    ];

    const columnsSmlFile = [
      {
        title: t("salesmeasure.smlfilename"),
        dataIndex: "smlName",
        key: "smlName",
      },
    ];
    const rowSelectionSmlFile = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: Sml[];
      onChange: Function;
    }>({
      selectedRowKeys: [],
      selectedRowData: [],
      onChange: (selectedRowKeys: Key[], selectedRows: Sml[]) => {
        rowSelectionSmlFile.selectedRowKeys = selectedRowKeys;
        rowSelectionSmlFile.selectedRowData = selectedRows;
      },
    });
    //query Params
    const queryParams = reactive<QueryDetailParams>({
      programNameCn: "",
      programNameEn: "",
      programCode: "",
      releaseBatchId: "",
    });
    Object.assign(queryParams, {
      releaseBatchId: route.query.releaseBatchId,
    });

    const rowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: DetailParams[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: DetailParams[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
    });
    const rowSelectionAt = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: DetailAttachment[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: DetailAttachment[]) => {
        rowSelectionAt.selectedRowKeys = selectedRowKeys;
        rowSelectionAt.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
    });

    const { data: programAttachment, refreshFetch } = useFetch<DetailParams[]>(
      queryParams,
      [],
      searchReleaseBatchContent,
      true,
      (res: DetailAttachmentProgram) => {
        rowSelection.selectedRowKeys = res.program.map(
          (item: DetailParams, index: number) => index
        );
        rowSelectionAt.selectedRowKeys = res.attachment.map(
          (item: DetailAttachment, index: number) => index
        );
        rowSelection.selectedRowData = res.program.map(
          (item: DetailParams, index: number) => item
        );
        rowSelectionAt.selectedRowData = res.attachment.map(
          (item: DetailAttachment, index: number) => item
        );
        rowSelectionSmlFile.selectedRowData = res.sml.map(
          (item: Sml, index: number) => item
        );
        rowSelectionSmlFile.selectedRowKeys = res.sml.map(
          (item: Sml, index: number) => index
        );
      }
    );

    //查询bu options
    const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu);

    const handlerResetClick = (): void => {
      Object.assign(queryParams, {
        programNameCn: "",
        programNameEn: "",
        programCode: "",
        releaseBatchId: route.query.releaseBatchId,
      });
      refreshFetch(1);
    };

    const handlerSearchClick = (): void => {
      refreshFetch(1);
    };

    const handlerDownload = () => {
      console.log(
        "downlod.......",
        rowSelectionAt.selectedRowData.length,
        rowSelectionAt.selectedRowKeys.length
      );
      if (
        rowSelection.selectedRowData.length < 1 &&
        rowSelectionAt.selectedRowData.length < 1 &&
        rowSelectionSmlFile.selectedRowData.length < 1
      ) {
        message.error(t("salesmeasure.pleaseSelect"));
        return;
      }

      const params = {
        attachmentId: rowSelectionAt.selectedRowData.map(
          (item: DetailAttachment) => item.attachmentId
        ),
        programId: rowSelection.selectedRowData.map(
          (item: DetailParams) => item.programId
        ),
        smlId: rowSelectionSmlFile.selectedRowData.map(
          (item: Sml) => item.smlId
        ),
      };
      blobDownload({
        url: "/pgapi/sales_measure/download_release_batch_content",
        method: "post",
        params: params,
      });
    };

    const closeDealerModal = () => {
      allDealerVisiable.value = false;
    };
    console.log(route.query.allDealerVisiable);
    onMounted(() => {
      if (route.query.isUserBu == "true") {
        allDealerVisiable.value = false;
      } else {
        allDealerVisiable.value = true;
      }
    });

    return {
      //query Params
      ...toRefs(queryParams),

      //table
      allDealerVisiable,

      columns,
      columnsAttachment,
      programAttachment,
      tableHeight,

      //事件处理
      handlerSearchClick,

      //搜索的options
      buOptions,

      // moment
      moment,
      handlerResetClick,
      rowSelection,
      rowSelectionAt,
      handlerDownload,
      columnsSmlFile,
      rowSelectionSmlFile,
      closeDealerModal,
    };
  },
});
